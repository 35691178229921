import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "OpenAPI や Protocol Buffers のおかげで開発がかなり捗っている話",
  "date": "2020-08-21T10:09:34.000Z",
  "slug": "entry/2020/08/21/190934",
  "tags": ["medley"],
  "hero": "./2020_08_21.png",
  "heroAlt": "OpenAPI"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、インキュベーション本部エンジニアの加藤です。
主に `}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS アプリ`}</a>{`の開発を担当しています。`}</p>
    <h1>{`はじめに`}</h1>
    <p>{`CLINICS アプリの開発では `}<a parentName="p" {...{
        "href": "https://www.openapis.org/"
      }}>{`OpenAPI`}</a>{` や `}<a parentName="p" {...{
        "href": "https://grpc.io/"
      }}>{`gRPC`}</a>{` を利用しています。
OpenAPI と gRPC の間には何の関係もないのですが、どちらも API の仕様をスキーマ言語で記述するという点では共通しています。
今回はこの API スキーマが開発にもたらすメリットについて紹介していこうと思います。`}</p>
    <h1>{`API ドキュメントとしてのスキーマ定義`}</h1>
    <p>{`既存のコードに機能を追加する際や修正を加える際に気にすることの多い部分は API の仕様ではないかと思います。
「リクエストやレスポンスはどのようなデータなのか」「この値は必須なのか、任意なのか」「データの型は数値なのか、文字列なのか」「フォーマットは決まっているのか」など、多くのことを把握する必要があります。`}</p>
    <p>{`しかし、こういった API 仕様のドキュメントが整備されていないということも多いのではないでしょうか。
また、ドキュメントはあるけれどリクエストとレスポンスのサンプル JSON が貼ってあるだけだったり、時間が経つうちに実装とドキュメントが乖離してしまいアテにならなくなってしまっている場合もあります。
参考にできるドキュメントがない場合は、直接バックエンドの実装を見に行くこともあるのですが、目的のコードを探し出して読み解くのは意外と時間がかかります。
特に自分以外のエンジニアが実装した機能の場合、API の仕様を実装から紐解くのはかなり骨が折れる作業です。`}</p>
    <p>{`CLINICS アプリの開発ではこういった手間を減らすために OpenAPI や gRPC を利用しています。
REST API のスキーマは OpenAPI を使って記述しています。
gRPC を利用している部分は `}<a parentName="p" {...{
        "href": "https://developers.google.com/protocol-buffers"
      }}>{`Protocol Buffers`}</a>{` で RPC のインターフェースが記述されるため、これが API のスキーマになっています。
OpenAPI や Protocol Buffers の定義を参照することで API の仕様が容易に把握できるため、非常に便利です。`}</p>
    <p>{`OpenAPI や Protocol Buffers などのスキーマ言語の良い点は、宣言的な DSL でスキーマを定義できる点です。
実装言語に依存しない形での記述ができ、自然言語のような曖昧さもないためより正確に API 仕様を記述することができます。`}</p>
    <p>{`ここからは OpenAPI や Protocol Buffers の実際に記述例を挙げながら、どういった形でスキーマを記述していくのか簡単に見ていきたいと思います。`}</p>
    <h1>{`OpenAPI`}</h1>
    <p>{`OpenAPI は REST API のインターフェースを記述する仕様です。
エンドポイントのリクエストパラメータやレスポンスの構造などを JSON や YAML で記述していくことができます。
かつては Swagger という名称だったため、そちらの名前で知っているという方もいらっしゃるかもしれません。`}</p>
    <p>{`実際の OpenAPI の定義は以下のような形になります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yaml"
    }}><pre parentName="div" {...{
        "className": "language-yaml"
      }}><code parentName="pre" {...{
          "className": "language-yaml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`openapi`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 3.0.2
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`info`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`title`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` OpenAPI Sample`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`version`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` 1.0.0 `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`paths`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`"/users/{user_id}"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`get`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`parameters`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` user_id
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`in`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` path
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`required`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`schema`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` string `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`responses`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`"200"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`description`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` OK
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`content`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
            `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`application/json`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
              `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`schema`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
                `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` object
                `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`properties`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
                  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` string `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
                  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`name`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` string `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
                  `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`age`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` integer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`nullable`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean important"
          }}>{`true`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
                `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`required`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` name`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` age`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`"404"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`description`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Not Found
        `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`"500"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
          `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`description`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` Internal Server Error`}</code></pre></div>
    <p>{`例としてユーザー ID からユーザーのデータを取得する API を記述してみました。
上記の定義を見ると、`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`GET: /users/{user_id}`}</code>{` というエンドポイントがある`}</li>
      <li parentName="ul">{`ユーザーの ID (`}<code parentName="li" {...{
          "className": "language-text"
        }}>{`user_id`}</code>{`) を string でパラメータとしてパスの中に埋め込む`}</li>
      <li parentName="ul">{`レスポンスは 200, 404, 500 が返ってくる可能性がある`}</li>
    </ul>
    <p>{`といったことが読み取れるかと思います。
また、レスポンスは以下のような JSON が返ってくることもスキーマから読み取ることができます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"12345678-1234-1234-1234-123456789abc"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"name"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"user name"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"age"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`25`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`ここでは OpenAPI の記述の仕方についてあまり深く触れませんが、この他にもデータのフォーマットや省略された場合のデフォルト値などの記述なども可能です。
より詳しくは`}<a parentName="p" {...{
        "href": "https://swagger.io/docs/specification/about/"
      }}>{`こちら`}</a>{`を参考にしてみてください。
これらをうまく使うことでより正確に API の仕様を記述することができます。`}</p>
    <h1>{`Protocol Buffers`}</h1>
    <p>{`Protocol Buffers は主に gRPC で用いられるデータのシリアライゼーション形式です。
専用のスキーマ言語を用いてデータの構造や RPC のメソッドの定義を `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.proto`}</code>{` ファイルに記述していきます。`}</p>
    <p>{`実際の `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.proto`}</code>{` ファイルは以下のような形になります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "protobuf"
    }}><pre parentName="div" {...{
        "className": "language-protobuf"
      }}><code parentName="pre" {...{
          "className": "language-protobuf"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`syntax`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"proto3"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`package`}</span>{` example`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`protobuf`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`service`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`UserService`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`rpc`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`GetUser`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`GetUserRequest`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`returns`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`GetUserResponse`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`message`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`GetUserRequest`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span>{` id `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`message`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`GetUserResponse`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token positional-class-name class-name"
          }}>{`User`}</span>{` user `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`message`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`User`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span>{` id `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`string`}</span>{` name `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token builtin"
          }}>{`int32`}</span>{` age `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`少し独特な見た目をしていますが、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`message`}</code>{` として構造体のようなデータ構造が定義されていることが見て取れると思います。
各メッセージにはフィールドが定義されており、型とフィールド名が宣言されています。`}</p>
    <h1>{`スキーマ言語を使うメリット`}</h1>
    <p>{`スキーマはドキュメントの代わりとして使えるだけでなく、他にもいくつか使い道があるので紹介していこうと思います。`}</p>
    <h2>{`スキーマからソースコードを自動生成できる`}</h2>
    <p>{`スキーマ言語による記述は人間にとって読みやすいだけでなく機械的に処理できる形式でもあるので、スキーマからソースコードを自動生成することも可能です。
プログラミング言語や使っているフレームワークによらず、サーバー側ではリクエストのバリデーションを、またクライアント側ではレスポンスの JSON をクラスや構造体に詰めるような処理を書くことが多いのではないでしょうか。
事前にスキーマ定義を書いている場合はスキーマを見ながらこういったコードを書き起こしていくことになるのですが、せっかく machine-readable な形のスキーマがあるのだから自動でコードを生成したくなるのが自然な考えでしょう。
自動で生成してしまえば定型のコードを書く手間を減らせるだけでなく、手書きするとどうしても起こしがちな JSON のキー名を間違えるといったミスを防ぐこともできます。
また、Web, iOS, Android などの複数プラットフォームでサービスを展開している場合でも、それぞれの実装が乖離しないように管理していくことも容易です。`}</p>
    <p>{`gRPC の場合は Protocol Buffers からソースコードを生成するのがほぼ前提となっています。
protoc コマンドを利用すると `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.proto`}</code>{` ファイルから各言語のサーバー・クライアントコードを生成することができます。`}</p>
    <p>{`OpenAPI の場合、`}<a parentName="p" {...{
        "href": "https://github.com/OpenAPITools/openapi-generator"
      }}>{`openapi-generator`}</a>{` や `}<a parentName="p" {...{
        "href": "https://github.com/swagger-api/swagger-codegen"
      }}>{`swagger-codegen`}</a>{` などのツールを利用することで各種言語のコードを自動生成が可能です。
openapi-generator や swagger-codegen には多くの言語とフレームワークのサーバースタブと API クライアントのジェネレータが用意されています。`}</p>
    <p>{`既存のジェネレータを使うだけでも十分に強力なのですが、さらに自前でコードジェネレータを書けばプロジェクト固有のルールでコードを生成するといったことも可能です。
OpenAPI のドキュメント自体はただの YAML/JSON なので、パースしてスキーマ定義を再帰的に読んでいけばコード生成に必要な情報を集めることができます。
Protocol Buffers からコードを生成する場合は `}<a parentName="p" {...{
        "href": "https://qiita.com/yugui/items/87d00d77dee159e74886"
      }}>{`protoc のプラグインを書く`}</a>{`のが簡単でオススメです。`}</p>
    <h2>{`モックサーバーを使ってクライアント開発を進められる`}</h2>
    <p>{`コードの自動生成の他にもスキーマからモックサーバを立ち上げるといった活用方法もあります。
`}<a parentName="p" {...{
        "href": "https://github.com/stoplightio/prism"
      }}>{`Prism`}</a>{` のようなスキーマ定義から固定のデータを返すモックサーバを立ち上げるツールを利用すれば、クライアント側の開発もしやすくなります。
特にフロントエンドとバックエンドで担当が分かれているような場合は、開発初期はモックサーバーを使って開発を始め、バックエンドの実装ができてきた頃に結合するという流れを踏むことで、フロントエンドもより早いタイミングから開発に着手することができるようになります。`}</p>
    <p>{`実際に Prism を使ってモックサーバーを立ち上げるとこんな感じになります。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200821/20200821181330.png",
      "alt": "20200821181330.png"
    }}></img>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20200821/20200821181342.png",
      "alt": "20200821181342.png"
    }}></img>
    <h1>{`スキーマファースト開発`}</h1>
    <p>{`このような開発スタイルはスキーマファースト開発・スキーマ駆動開発などと呼ばれています。
明確な定義や出典があるわけではないのですが、スキーマファーストな開発は以下のような開発スタイル全般を指しています。`}</p>
    <ul>
      <li parentName="ul">{`スキーマは実装言語によらない machine-readable な形で記述する`}</li>
      <li parentName="ul">{`ドキュメントをスキーマから自動生成する`}</li>
      <li parentName="ul">{`クライアント/サーバーのコードをスキーマから自動生成する`}</li>
    </ul>
    <p>{`実際にスキーマファーストな開発を実践するためにはスキーマをメンテナンスするモチベーションを高く保つ必要があります。
コードも書いた上でスキーマも書かないいけないとなるとおそらくスキーマが更新されなくなっていくので、可能な限りスキーマからコードを生成する努力が必要になります。
一方、うまく実践すればスキーマという形で 100% 信頼できる API ドキュメントが手に入り、クライアント/サーバー間での齟齬も減らせるので、実践してみる価値はあるのではないかと思います。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`今回は CLINICS アプリの開発でのスキーマ言語の活用例について紹介しました。
実際に開発の中でスキーマ言語を使うことで API 仕様について把握する労力が減り、エンジニア間のコミュニケーションも取りやすくなったと感じています。
また、一部のクライアントコードは OpenAPI や Protocol Buffers から自動生成しているのですが、かなり手間が省けると同時に人間の手で書くと起こりがちなミスも防ぐことができています。
OpenAPI であれば既存の REST API の仕様を書き起こすところから使い始められるので、興味のある方はぜひ一度使ってみてはいかがでしょうか。`}</p>
    <h1>{`最後に`}</h1>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/team/creator-story-incubation.html"
      }}>{`インキュベーション本部`}</a>{`では「医療ヘルスケアの未来」をつくる新規事業の立ち上げに挑戦しています。そんな私たちと一緒に働いてみたいと思った方は、ぜひ以下の採用情報もチェックしてみてください。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      